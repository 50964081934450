import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

import Helpers from '../../theme/Helpers';
import SVGIcon from '../SVGIcon/SVGIcon';
import GemsPayListContainer from '../../containers/Gems/GemsPayList';

import conf from '../../conf';
import { withRouter } from 'react-router-dom';
import { multiplyEnergyCoins } from '../../utils/helper';

const useStyles = makeStyles(() => ({
  gemsArea: {
    boxShadow: 'inset 0px 0px 18px #E1213899',
    height: 62,
    width: 62,
    borderLeft: '3px solid rgb(1, 16, 36)',
    background: 'rgba(225, 33, 56, 0.2)',
    '& > div': {
      ...Helpers.fillColCenter,
      width: '100%',
      cursor: 'pointer',
      height: '100%',
      transition: 'all 0.3s ease-out',
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.14)',
      },
    },
  },
}));

const TopBarGems = (props) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const { viewer } = props;

  const gems = viewer && viewer.premium_currency ? viewer.premium_currency : 0;
  const coins = viewer && viewer.sh_coins ? viewer.sh_coins : 0;

  return (
    <>
      <div style={{ position: 'relative', ...Helpers.fillRow }}>
        <div
          className={classes.gemsArea}
          style={{
            background: 'rgba(255, 254, 71, 0.2)',
            boxShadow: 'inset 0px 0px 18px #FFFE4799',
          }}
        >
          <div onClick={() => props.history.push('/bank')}>
            <img
              src={`${conf.cdn_url}/assets/template/sh_coin.png?height=25`}
              alt="SH Coins"
            />
            <div style={{ marginTop: 2, fontSize: 12 }}>
              {multiplyEnergyCoins(coins)}
            </div>
          </div>
        </div>
        <div className={classes.gemsArea}>
          <div onClick={() => setIsOpen(!isOpen)}>
            <SVGIcon
              src={`${conf.cdn_url}/assets/template/icons/gems.svg`}
              height={25}
              width={19}
            />
            <div style={{ marginTop: 2, fontSize: 12 }}>
              {gems}
            </div>
          </div>
        </div>
      </div>

      {isOpen &&
      <div style={{ position: 'absolute', right: 0, top: 'calc(100% + 3px)', zIndex: 10 }}>
        <GemsPayListContainer gems={gems} onClose={() => setIsOpen(false)} />
      </div>
      }
    </>
  );
};

export default withRouter(TopBarGems);