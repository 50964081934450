import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useSnackbar } from 'notistack';
import './custom.css';

import { hideForPathURL } from '../../utils/helper';
import Helpers from '../../theme/Helpers';
import CustomBackdropFilter from '../Layout/CustomBackdropFilter';
import Colors from '../../theme/Colors';
import SVGIcon from '../SVGIcon/SVGIcon';
import CustomTooltip from '../Tooltip/CustomTooltip';
import DepotInfo from './DepotInfo';
import ToggleMenu from './ToggleMenu';
import Sidebar from './Sidebar';
import RightSidebar from './RightSidebar';
import TopBar from './TopBar';
import {
  toggleCollectingChecker,
  toggleNotifications,
  toggleSearch,
  toggleShoppingCart,
} from '../../redux/actions';

import HeadquarterIcon from '../Icon/Duotone/HeadquarterIcon';
import DepotIcon from '../Icon/Duotone/DepotIcon';
import InventoryIcon from '../Icon/Duotone/InventoryIcon';
import ItemStoreIcon from '../Icon/Duotone/ItemStoreIcon';
import CardShopIcon from '../Icon/Duotone/CardShopIcon';
import LeaderboardIcon from '../Icon/Duotone/LeaderboardIcon';
import NotificationIcon from '../Icon/Duotone/NotificationIcon';
import SearchIcon from '../Icon/Duotone/SearchIcon';
import StreamerIcon from '../Icon/Duotone/StreamerIcon';
import SettingsIcon from '../Icon/Duotone/SettingsIcon';

const conf = require('../../conf');

const useStyles = makeStyles(() => ({
  root: {
    width: 64,
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    zIndex: 101,
    background: 'rgba(255, 255, 255, 0.04)',
    color: Colors.white,
    transition: 'all 0.3s ease-out',
    overflow: 'initial',
  },
  expandedRoot: {
    width: 170,

    '& $menuItem': {
      width: 170,
      fontSize: 12,
      ...Helpers.mainStart,
      '& > div:last-child': {
        paddingLeft: 25,
      },
    },
    '& $badge': {
      position: 'initial',
      marginLeft: 8,
    },
    '& $inventoryArea': {
      marginLeft: -6,
      '& > div > div > div:last-child': {
        paddingLeft: 18,
      },
    },
  },
  itemCount: {
    background: 'rgba(225, 33, 56, 0.21)',
    color: '#E12138',
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 12,
    width: 24,
    height: 24,
    ...Helpers.fillRowCenter,
    borderRadius: 4,
    marginLeft: 4,
  },
  menuItem: {
    height: 12,
    margin: '2px 0',
    padding: '12px 0',
    position: 'relative',
    cursor: 'pointer',
    width: 64,
    transition: 'all 0.3s ease-out',
    color: Colors.halfWhite,
    ...Helpers.fillRowCenter,
    '& .leftBar': {
      position: 'absolute',
      left: 0,
      opacity: 0,
      top: 0,
      bottom: 0,
      width: 4,
      borderRadius: '0px 5px 5px 0px',
      background: 'rgba(255, 255, 255, 0.08)',
      transition: 'all 0.3s ease-out',
    },

    '&:hover': {
      color: Colors.white,
      '& .leftBar': {
        opacity: 1,
      },
    },

    '&.active': {
      color: Colors.white,

      '& .leftBar': {
        background: '#E12138',
        opacity: 1,
      },
    },

    '&.disabled': {
      color: 'rgba(255, 255, 255, 0.25)',
      cursor: 'not-allowed',
    },
  },
  avatar: {
    height: 20,
    width: 20,
    transition: 'all 0.3s ease-out',
    border: '1px solid #FFFFFF47',
  },
  badge: {
    background: '#E12138',
    borderRadius: 11,
    height: 14,
    width: 20,
    position: 'absolute',
    zIndex: 1,
    top: -4,
    right: -14,
    ...Helpers.fillColCenter,
    fontSize: 10,
    fontWeight: 500,
    color: Colors.white,
  },
  coinItem: {
    width: 40,
    height: 40,
    transition: 'all 0.3s ease-out',
  },
  inventoryArea: {
    marginTop: 12,
  },
  hoverArea: {
    transition: 'all 0.3s ease-out',
    cursor: 'pointer',
    color: Colors.halfWhite,

    '&:hover, &.active': {
      color: Colors.white,

      '& i': {
        transition: 'all 0.3s ease-out',
        color: Colors.white,
      },
      '& $avatar': {
        borderColor: '#E12138 !important',
      },
    },
  },
  tooltip: {
    backgroundColor: 'rgba(255,255,255,0.08)',
    color: Colors.white,
    fontSize: 12,
    padding: '16px 14px',
    backdropFilter: 'blur(25px)',
  },
  tooltipArrow: {
    color: 'rgba(255,255,255,0.08)',
  },
}));

const MenuComponent = (props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    viewer,
    redeemableBoosterCount,
    location,
    extra,
    coinsTankInfo,
    tanks,
    streamer,
    alertWebSourceUrls,
    followedStreamers,
    liveChannels,
    cardDecks,
  } = props;
  const [clicked, setClicked] = useState(null);

  if (hideForPathURL(location.pathname))
    return null;

  const topMenu = [
    {
      icon: 'fa-light fa-sack',
      CustomIcon: <HeadquarterIcon />,
      title: t('headquarter'),
      link: '/hq/hub',
      group: '/hq',
    },
    {
      CustomIcon: <ItemStoreIcon />,
      title: t('item_store'),
      link: '/item-store/booster',
      group: '/item-store',
      count: redeemableBoosterCount ? redeemableBoosterCount : 0,
    },
    {
      CustomIcon: <DepotIcon />,
      title: t('bank'),
      customTooltip: viewer &&
        <DepotInfo tanks={tanks} viewer={viewer} coinsTankInfo={coinsTankInfo} />,
      link: '/bank',
      group: '/bank',
      customPlacement: 'right-start',
      ownClass: classes.hoverArea,
      ownClasses: {
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
      },
    },
    {
      CustomIcon: <InventoryIcon />,
      title: t('inventory'),
      link: '/inventory/groups',
      group: '/inventory',
    },
    {
      icon: 'fa-solid fa-store',
      title: t('marketplace'),
      link: '/marketplace/home',
      group: '/marketplace',
    },
    {
      CustomIcon: <CardShopIcon />,
      title: t('card_shop'),
      link: '/card-shop',
      group: '/card-shop',
      disabled: false,
    },
    {
      CustomIcon: <LeaderboardIcon />,
      title: t('global_leaderboard'),
      link: '/ranking/streamer',
      group: '/ranking',
    },
    {
      icon: 'fa-light fa-bolt',
      title: t('energy'),
      link: '/energy',
      group: '/energy',
    },
  ];

  const bottomMenu = [
    {
      icon: 'fa-solid fa-user',
      avatar: viewer && <Avatar
        className={classes.avatar}
        alt="Avatar"
        src={viewer.profile_image_url}
      />,
      title: t('profile'),
      link: viewer && `/profile/${viewer.login}/overview`,
      group: viewer && `/profile/${viewer.login}`,
    },
    /*{
      CustomIcon: <NotificationIcon />,
      title: t('notifications'),
      action: () => props.toggleNotifications(),
    },*/
    {
      CustomIcon: <SearchIcon />,
      title: t('search'),
      action: () => props.toggleSearch(),
    },
    {
      icon: 'fa-brands fa-discord',
      title: 'Discord',
      action: () => window.open('https://discord.gg/WJE6b8M', '_blank'),
    },
    {
      CustomIcon: <StreamerIcon />,
      title: t('streamer_settings'),
      link: '/streamer-settings/streamer',
      group: '/streamer-settings/',
    },
    {
      CustomIcon: <SettingsIcon />,
      title: t('settings'),
      link: '/settings/account',
      group: '/settings',
    },
  ];

  if (window.localStorage.getItem(`${conf.storage_keys.user_token}:TMP`))
    bottomMenu.push({
      icon: 'fa-light fa-user-slash',
      title: t('end_impersonation'),
      action: () => endImpersonation(),
    });

  const roles = viewer && viewer.streamheroes_role;
  if (roles && (roles.includes('admin') || roles.includes('marketing')))
    if (!window.localStorage.getItem(`${conf.storage_keys.user_token}:TMP`))
      bottomMenu.push({
        icon: 'fa-light fa-user-ninja',
        title: t('admin_area'),
        link: '/admin/impersonate',
        group: '/admin',
      });

  const endImpersonation = () => {
    const originalToken = window.localStorage.getItem(`${conf.storage_keys.user_token}:TMP`);
    localStorage.setItem(conf.storage_keys.user_token, originalToken);
    localStorage.removeItem(`${conf.storage_keys.user_token}:TMP`);
    window.location.href = '/hq/hub';
    sessionStorage.removeItem('ttlQueries');
  };

  const getMenu = (menuItems, withArrow = false) => (
    <div style={Helpers.fillColCenter}>
      {menuItems.map(item => {
        const isActive = clicked === item.title || location.pathname.includes(item.group);

        return (
          <CustomTooltip
            title={item.customTooltip ? item.customTooltip : item.title}
            placement={item.customPlacement ? item.customPlacement : 'right'}
            key={item.title}
            disabled={item.customTooltip ? false : isExpanded}
            ownClasses={item.ownClasses && item.ownClasses}
          >
            <div
              className={[classes.menuItem, item.ownClass ? item.ownClass : '', isActive ? 'active' : '', item.disabled ? 'disabled' : ''].join(' ')}
              onClick={() => {
                if (item.disabled) {
                  enqueueSnackbar(t('card_shop_temporarily_closed'), { variant: 'error' });
                  return;
                }

                if (item.link)
                  props.history.push(item.link);

                if (item.action)
                  item.action();

                // disable search if user clicks on another menu item
                if (extra.showSearch && item.title !== t('search'))
                  props.toggleSearch();

                // disable Notifications if user clicks on another menu item
                if (extra.showNotifications && item.title !== t('notifications'))
                  props.toggleNotifications();

                setClicked(item.title);
              }}
            >
              <div className="leftBar" />
              <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <div style={{ width: withArrow ? 16 : 18, ...Helpers.fillRowCenter }}>
                  {
                    item.avatar ?
                      item.avatar
                      :
                      item.CustomIcon ?
                        React.cloneElement(
                          item.CustomIcon,
                          { forceActive: isActive },
                        ) :
                        <i
                          className={isActive ? item.icon.replace('light', 'solid') : item.icon}
                          style={{ fontSize: 16 }}
                        />
                  }
                </div>
                {isExpanded &&
                <span style={{ marginLeft: 14 }}>{item.title}</span>
                }
                {item.count > 0 &&
                <div className={classes.badge}>
                  {item.count}
                </div>
                }
                {(withArrow && !isExpanded) &&
                <i className="fa-regular fa-angle-right" style={{
                  fontSize: 8,
                  position: 'absolute',
                  right: -8,
                  top: '50%',
                  transform: 'translate(0, -50%)',
                }} />
                }
              </div>
            </div>
          </CustomTooltip>
        );
      })}
    </div>
  );

  const isExpanded = extra.expandedMenu;

  return (
    <>
      <CustomBackdropFilter
        className={[classes.root, isExpanded ? classes.expandedRoot : ''].join(' ')}
        filter={'blur(50px)'}
        canvasFallback={true}
        html2canvasOpts={{
          allowTaint: true,
        }}
      >
        <div style={{
          ...Helpers.fillColCenter,
          ...Helpers.mainSpaceBetween,
          height: '100%',
          position: 'relative',
        }}>

          <div>
            <CustomTooltip
              title={t('overview')}
              placement="right"
              disabled={isExpanded}
            >
              <Link
                to={`/hq/hub`}
                onClick={() => setClicked(null)}
                style={{ ...Helpers.fillRowCross, marginTop: 16, marginLeft: 10, marginBottom: 27 }}
              >
                <SVGIcon src={`${conf.cdn_url}/icons/logo.svg`} size={45} />
              </Link>
            </CustomTooltip>

            {getMenu(topMenu)}
          </div>

          <div style={{ marginBottom: 10 }}>
            {getMenu(bottomMenu)}
          </div>

          <ToggleMenu />
        </div>
      </CustomBackdropFilter>

      <Sidebar
        streamer={streamer}
        roles={roles}
        viewer={viewer}
      />

      <RightSidebar followedStreamers={followedStreamers} liveChannels={liveChannels} />

      <TopBar alertWebSourceUrls={alertWebSourceUrls} cardDecks={cardDecks} viewer={viewer} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { extra } = state;
  return { extra };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    toggleShoppingCart,
    toggleSearch,
    toggleNotifications,
    toggleCollectingChecker,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MenuComponent));