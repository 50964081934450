import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { getMyShopCardsByMatch } from '../../apollo/mergedQueries';

import ErrorMessage from '../../components/ErrorBoundary/ErrorMessage';
import CardShop from '../../components/CardShop/CardShop';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCacheWithExpiration } from '../../utils/useCacheWithExpiration';
import { logAmplitudeEvent } from '../../utils/amplitude';
import conf from '../../conf'

function CardShopContainer() {
  const { t } = useTranslation();
  const [isAmplitudeSend, setIsAmplitudeSend] = useState(false);
  const [match, setMatch] = useState(null);
  const [page, setPage] = useState(2);
  const perPage = 25;

  const { data, loading, error, fetchMore } = useQuery(getMyShopCardsByMatch, {
    variables: { pagination: { page: 1, perPage }, match },
    fetchPolicy: useCacheWithExpiration('getMyShopCardsByMatch'),
  });

  const matches = data && data.getMyShopCardsByMatch;
  const tweets = data && data.recentTweets;
  const shopBoosterPacks = data && data.getShopBoosterPacks;
  const myViewer = data && data.getMyViewer;

  useEffect(() => {
    if (!isAmplitudeSend && matches && conf.track_amplitude) {
      setIsAmplitudeSend(true);
      logAmplitudeEvent('View Shop', { 'Count of matches available': matches.length });
    }
  }, [matches, isAmplitudeSend]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) return <ErrorMessage error={error} />;

  return (
    <>
      <PageTitle title={t('card_shop')} />
      <CardShop
        loading={loading}
        myViewer={myViewer}
        hasMore={matches && matches.streamer_count > matches.streamer_cards.length}
        matches={matches}
        setMatch={setMatch}
        tweets={tweets}
        match={match}
        shopBoosterPacks={shopBoosterPacks}
        onLoadMore={async () => {
          setPage(page + 1);
          try {
            await fetchMore({
              variables: { pagination: { perPage, page } },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                return {
                  getMyShopCardsByMatch: {
                    streamer_cards: [...prev.getMyShopCardsByMatch.streamer_cards, ...fetchMoreResult.getMyShopCardsByMatch.streamer_cards],
                    __typename: prev.getMyShopCardsByMatch.__typename,
                  },
                };
              },
            });
          } catch {
          }
        }}
      />
    </>
  );
}

export default CardShopContainer;