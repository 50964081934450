import React, { useState } from 'react';

import CardShopContainer from './CardShop';
import LegacyCardShopContainer from './LegacyCardShop';

const LegacyCardShopSwitcherContainer = () => {
  const [isLegacy, setIsLegacy] = useState(false);

  return (
    !isLegacy ?
      <CardShopContainer isLegacy={isLegacy} setIsLegacy={setIsLegacy} />
      :
      <LegacyCardShopContainer isLegacy={isLegacy} setIsLegacy={setIsLegacy} />
  );
};

export default LegacyCardShopSwitcherContainer;