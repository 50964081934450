import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Helpers from '../../theme/Helpers';

const ArrowButton = (props) => {
  const { onClick, direction } = props;

  const useStyles = makeStyles(() => ({
    button: {
      background: 'rgba(255, 255, 255, 0.08)',
      transition: 'all 0.3s ease-out',
      cursor: 'pointer',
      width: 44,
      height: 44,
      borderRadius: 4,
      margin: '0 3px',
      ...Helpers.fillColCenter,
      '&:hover': {
        background: 'rgba(255, 255, 255, 0.16)',
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.button} onClick={onClick}>
      <i className={`fa-light fa-chevron-${direction}`} style={{ fontSize: 18 }} />
    </div>
  );
};

export default ArrowButton;